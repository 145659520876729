import React from "react";
import { Pagination, A11y, Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import TimeCountDown from '../countdown'


import hero1 from '../../images/bjwedding/hero/avif/DSC_8689.avif'
import hero2 from '../../images/bjwedding/hero/avif/DSC_8670.avif'
import hero3 from '../../images/bjwedding/hero/avif/DSC_8808.avif'
import logo from '../../images/bjwedding/logo/LOGO-BRANDON-BLANCO.avif'


const HeroBJ = () => {
    return (

        <section className="wpo-hero-slider-s3" id="init">
            <h2 className="hidden">some</h2>
            <div className="swiper-container">
                <div className="swiper-wrapper">
                    <Swiper
                        // install Swiper modules
                        modules={[Pagination, A11y, Navigation, Autoplay]}
                        spaceBetween={0}
                        slidesPerView={1}
                        loop={true}
                        pagination={{ clickable: false }}
                        autoplay={{ delay: 2500 }} // Cambia automáticamente cada 3 segundos
                        speed={1800}
                        parallax={true}
                        allowTouchMove={false} // Desactiva el movimiento táctil y clics
                    >
                        <SwiperSlide>
                            <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero1})` }}>
                                <img src={logo} alt="Logo" className="logo-bj" />

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero2})` }}>
                                <img src={logo} alt="Logo" className="logo-bj" />

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero3})` }}>
                                <img src={logo} alt="Logo" className="logo-bj" />
                            </div>
                        </SwiperSlide>
                        ...
                    </Swiper>
                </div>
            </div>
            <div className="hero-inner-text">
                <h2>
                   Save the date
                </h2>
                <div className="counter" >
                    <div className="col col-xs-12">
                        <div className="clock-grids">
                            <div id="clock">
                                <TimeCountDown />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <ul className="social">
                    <li><Link to="/shop-home-2"><i className="fi flaticon-phone-call"></i></Link></li>
                </ul>    */}
            </div>
        </section>

    )
}

export default HeroBJ;