import React from 'react';
import SectionTitle from "../SectionTitle";
import gftImg from "../../images/bjwedding/gift-table/avif/gift-card.avif"
import gftImg2 from "../../images/bjwedding/gift-table/avif/gift-box.avif"

import 'react-toastify/dist/ReactToastify.css';

const GiftTable = () => {
    return (
        <section className="wpo-contact-pg-section section-padding-gift-table">
            <div className="container">
                <SectionTitle MainTitle={'Mesa de Regalos'} />
                <div className="row">
                    <div className="col col-lg-10 offset-lg-1">
                        <div className="office-info">
                            <div className="row">
                                <div className="col col-xl-6 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <img src={gftImg} alt="Amazon Gift Card" />
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Sobre</h2>
                                            <p>El día del evento</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-xl-6 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <img src={gftImg2} alt="Amazon Gift Card" />
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Regalo</h2>
                                            <p>El día del evento</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )

}

export default GiftTable;