import React from 'react';
import coupleImg1 from '../../images/bjwedding/couple/avif/result_DSC_8829.avif'
import coupleImg2 from '../../images/couple/couple-flower.png'
import vector1 from '../../images/couple/vector-1.svg'

const CoupleSectionBJ = (props) => {

    return (
        <section className="wpo-couple-section-s3 section-padding" id="couple">
            <div className="container-fluid">
                <div className="couple-area clearfix">
                    <div className="row align-items-center">
                        <div className="col col-xl-3 col-lg-4 col-12">
                            <div className="text-grid">
                                <div className="vector">
                                    <img src={vector1} alt="" />
                                </div>
                                <h3>Jacinto Ochoa</h3>
                                <p>Te miro y me doy cuenta que jamas pensé que podría ser posible amar con tanta
                                    intensidad a alguien, que encontré el amor y la calma que tanto necesitaba y
                                    en quien menos esperaba,encontrando mi hogar en unos brazos, que aunque no
                                    conocía, hace tiempo los anhelaba</p>
                            </div>
                        </div>
                        <div className="col col-xl-6 col-lg-4 col-12">
                            <div className="middle-couple-pic">
                                <div className="middle-couple-pic-inner">
                                    <img src={coupleImg1} alt="" />
                                </div>
                                <div className="couple-flower">
                                    <img src={coupleImg2} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col col-xl-3 col-lg-4 col-12">
                            <div className="text-grid">
                                <div className="vector">
                                    <img src={vector1} alt="" />
                                </div>
                                <h3>Brandon Belmares</h3>
                                <p>Con esta mano, yo sostendré tus anhelos, tu copa nunca estará vacía
                                    porque yo seré tu vino.
                                    Con esta vela alumbraré tu camino en la oscuridad y con este anillo te pido que seas mio.
                                    Y con este beso sello mi vida a tu destino.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CoupleSectionBJ;