import React from 'react'
import { Zoom } from "react-awesome-reveal";
import SectionTitle from '../SectionTitleBJWhite'
import sImg1 from '../../images/bjwedding/story/avif/DSC_8456.avif'
import sImg3 from '../../images/bjwedding/story/avif/DSC_8822.avif'

import bshape1 from '../../images/story/flower-shape1.svg'
import bshape2 from '../../images/story/flower-shape2.svg'
import bshape3 from '../../images/story/flower-shape3.svg'

const Storys = [
    {
        sImg: sImg1,
        shape: '',
        title: 'Nos conocimos',
        date: '30 de Mayo de 2012',
        description: 'Todo comenzó hace 12 años. Me miraste y te miré, y conectamos sin saber que esa mirada nos uniría para siempre, con tropiezos, lluvias, tormentas y huracanes, logrando consolidar nuestro amor. Quisimos huir pero nuestro destino ya estaba marcado y escrito, en un libro donde la historia se escribe sin un final.',
    },
   
    {
        sImg: sImg3,
        shape: '',
        title: 'Dijo que si',
        date: '25 de Junio de 2023',
        description: 'Una fecha que nunca olvidaremos, por esa gran y hermosa sorpreas que recibí al decir si, a este gran cambio, a esta nueva historia, escrita por los dos. Dije si al amor. Dije si al estar contigo, por la eternidad.',
    }

]


const StorySectionBJ = (props) => {
    return (
        <section className="wpo-story-section section-padding" id="story">
            <div className="container">
                <SectionTitle subTitle={'Nuestra historia'} MainTitle={'¿Cómo sucedió?'} />
                <div className="wpo-story-wrap">
                    {
                        Storys.map((story, st) => (
                            <div className="wpo-story-item" key={st}>
                                <div className="wpo-story-img-wrap">
                                    <Zoom direction="up" duration="1000" triggerOnce="true">
                                        <div className="wpo-story-img">
                                            <img src={story.sImg} alt="" />
                                        </div>
                                    </Zoom>
                                    <div className="clip-shape">
                                        <svg viewBox="0 0 382 440" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M191 0L381.526 110V330L191 440L0.474411 330V110L191 0Z" />
                                        </svg>
                                    </div>
                                    <div className="wpo-img-shape">
                                        <img src={story.shape} alt="" />
                                    </div>
                                </div>
                                <div className="wpo-story-content">
                                    <div className="wpo-story-content-inner">
                                        <span>{story.date}</span>
                                        <h2>{story.title}</h2>
                                        <p>{story.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))

                    }
                </div>
            </div>
            <div className="flower-shape-1">
                <img src={bshape1} alt="" />
            </div>
            <div className="flower-shape-2">
                <img src={bshape2} alt="" />
            </div>
            <div className="flower-shape-3">
                <img src={bshape3} alt="" />
            </div>
           
        </section>
    )
}

export default StorySectionBJ;