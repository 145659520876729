import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import BJHomPage from '../BJHomePage/BJHomePage';

const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<BJHomPage />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
