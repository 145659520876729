import React, { Fragment, useState, useEffect  } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import StorySectionBJ from '../../components/StorySectionBJ/StorySectionBJ';
import EventSectionBJ from '../../components/EventSectionBJ/EventSectionBJ';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'
import BJHero from '../../components/HeroBJ/HeroBJ';
import CoupleSectionBJ from '../../components/CoupleSectionBJ/CoupleSectionBJ';
import PortfolioSectionBJ from '../../components/PortfolioSectionBJ/PortfolioSectionBJ';
import BrideGrooms from '../../components/DressCodeBJ/DressCodeBJ';
import GiftTable from '../../components/GiftTableBJ/GiftTableBJ';
import Loader from '../../components/Loader/Loader'; // Importa el loader

const BJHomPage = () => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simula una carga, puedes usar promesas reales aquí
        const timer = setTimeout(() => {
            setLoading(false);
        }, 5000); // Ajusta el tiempo según sea necesario

        return () => clearTimeout(timer);
    }, []);

    return (
        <Fragment>
                        {loading && <Loader />} {/* Muestra el loader mientras se carga */}
            <Navbar hclass={'wpo-header-style-4'}/>
            <BJHero id="init"/>
            {/* <WeddingDate /> */}
            <CoupleSectionBJ />
            <StorySectionBJ />
            <EventSectionBJ id="#ceremony"/>
            <PortfolioSectionBJ/>
            <BrideGrooms id="dresscode" />
            <GiftTable/>
            <Footer footerClass={'wpo-site-footer-s2'}/>
            <Scrollbar />
        </Fragment>
    )
};

export default BJHomPage;