import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import pImg1 from '../../images/bjwedding/gallery/avif/DSC_8440.avif'
import pImg2 from '../../images/bjwedding/gallery/avif/DSC_8726.avif'
import pImg3 from '../../images/bjwedding/gallery/avif/DSC_8460.avif'
import pImg4 from '../../images/bjwedding/gallery/avif/DSC_8462.avif'
import pImg5 from '../../images/bjwedding/gallery/avif/DSC_8826.avif'
import pImg6 from '../../images/bjwedding/gallery/avif/DSC_8470.avif'
import pImg7 from '../../images/bjwedding/gallery/avif/DSC_8513.avif'
import pImg8 from '../../images/bjwedding/gallery/avif/DSC_8696.avif'
import pImg9 from '../../images/bjwedding/gallery/avif/DSC_8672.avif'
import pImg11 from '../../images/bjwedding/gallery/avif/DSC_8604.avif'
import pImg12 from '../../images/bjwedding/gallery/avif/DSC_8617.avif'
import pImg13 from '../../images/bjwedding/gallery/avif/DSC_8632.avif'
import pImg14 from '../../images/bjwedding/gallery/avif/DSC_8635.avif'
import pImg15 from '../../images/bjwedding/gallery/avif/DSC_8810.avif'
import pImg16 from '../../images/bjwedding/gallery/avif/DSC_8652.avif'
import pImg17 from '../../images/bjwedding/gallery/avif/DSC_8785.avif'
import pImg18 from '../../images/bjwedding/gallery/avif/DSC_8672.avif'


const Portfolios = [
    {
        Pimg: pImg1,
        style: "s1",
    },
    {
        Pimg: pImg2,
        style: "s1",
    },
    {
        Pimg: pImg3,
        style: "s1",
    },
    {
        Pimg: pImg4,
        style: "s1",
    },
    {
        Pimg: pImg5,
        style: "s1",
    },
    {
        Pimg: pImg6,
        style: "s1",
    },
    {
        Pimg: pImg7,
        style: "s1",
    },
    {
        Pimg: pImg8,
        style: "s1",
    },
    {
        Pimg: pImg9,
        style: "s1",
    },
    {
        Pimg: pImg11,
        style: "s1",
    },
    {
        Pimg: pImg12,
        style: "s1",
    },
    {
        Pimg: pImg13,
        style: "s1",
    },
    {
        Pimg: pImg14,
        style: "s1",
    },
    {
        Pimg: pImg15,
        style: "s1",
    },
    {
        Pimg: pImg16,
        style: "s1",
    },
    {
        Pimg: pImg17,
        style: "s1",
    },
    {
        Pimg: pImg18,
        style: "s1",
    }

]

const PortfolioSectionBJ = (props) => {

    const settings = {
        slidesToShow: 6,
        slidesToScroll: 1,
        focusOnSelect: true,
        arrows: false,
        dots: false,
        variableWidth: true,
        loop: false,
        autoplay: true,
        rtl: false,
        responsive:false,
    };
    const settings2 = {
        slidesToShow: 6,
        slidesToScroll: 1,
        focusOnSelect: true,
        arrows: false,
        dots: false,
        variableWidth: true,
        loop: false,
        autoplay: true,
        rtl: true,
        responsive:false,
    };

    const [open, setOpen] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(0); // Nuevo estado para el índice


    return (
        <section className="wpo-portfolio-section-s4 section-padding pb-0">
            <h2 className="hidden">some</h2>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="portfolio-grids gallery-container clearfix gallery-slide">
                            <Slider {...settings}>
                                {Portfolios.slice(0, 9).map((image, i) => (
                                    <div className={`grid ${image.style}`} key={i}>
                                        <div className="img-holder" onClick={() => { 
                                            setSelectedIndex(i); 
                                            setOpen(true)}}>
                                            <img src={image.Pimg} alt="" className="img img-responsive" />
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                        <div className="portfolio-grids gallery-container clearfix gallery-slide2">
                            <Slider {...settings2}>
                                {Portfolios.slice(9, 17).map((image, i) => (
                                    <div className={`grid ${image.style}`} key={i}>
                                                                                <div className="img-holder" onClick={() => { 
                                            setSelectedIndex(i + 9); 
                                            setOpen(true)}}>
                                            <img src={image.Pimg} alt="" className="img img-responsive" />
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={[
                    { src: pImg1 },
                    { src: pImg2 },
                    { src: pImg3 },
                    { src: pImg4 },
                    { src: pImg5 },
                    { src: pImg6 },
                    { src: pImg7 },
                    { src: pImg8 },
                    { src: pImg9 },
                    { src: pImg11 },
                    { src: pImg12 },
                    { src: pImg13 },
                    { src: pImg14 },
                    { src: pImg15 },
                    { src: pImg16 },
                    { src: pImg17 },
                    { src: pImg18 },
                ]}
                index={selectedIndex}
            />
        </section>
    )
}

export default PortfolioSectionBJ;