import React from 'react'
import { Slide } from "react-awesome-reveal";
import SectionTitle from "../SectionTitleBJGrey";
import LocationMap from './Modal'


const Events = [
    {
        Simg: '',
        title: 'La ceremonia',
        li1: 'Sábado, 07 de Diciembre, 2024 4:00 PM',
        li2: 'C. Diego de Montemayor #227, Centro, C.P.64000, Monterrey, N.L.',
        li3: '',
        animation: '1200',
        address: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3595.9131712385624!2d-100.30753042387667!3d25.67417287740704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662be2acabe8291%3A0x507fe439034fab8e!2sC.%20Diego%20de%20Montemayor%20227%2C%20Centro%2C%2064000%20Monterrey%2C%20N.L.!5e0!3m2!1ses-419!2smx!4v1729240849172!5m2!1ses-419!2smx'
    },
    {
        Simg: '',
        title: 'La recepción',
        li1: 'Sábado, 07 de Diciembre, 2024 - Salón 8:00 PM',
        li2: 'Calle Zaragoza #502, Sur, Apodaca centro, C.P.66600, Ciudad Apodaca, N.L.',
        li3: '',
        animation: '1400',
        address: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3592.78455356772!2d-100.18976532387387!3d25.77767887734187!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662ec09fe51e773%3A0x887c136b7d80660f!2sCasino%20Presidencial!5e0!3m2!1ses-419!2smx!4v1729240963403!5m2!1ses-419!2smx'
    },
]

const EventSectionBJ = (props) => {
    return (
        <section className="wpo-event-section-s4 section-padding" id="ceremony">
            <div className="container">
                <SectionTitle subTitle={'Nuestra boda'} MainTitle={'¿Cuándo y dónde?'} />
                <div className="wpo-event-wrap">
                    <div className="row">
                        {Events.map((event, eitem) => (
                            <div className="col col-lg-6 col-md-6 col-12" key={eitem}>
                                <Slide direction="up" duration={event.animation} triggerOnce="true">
                                    <div className="wpo-event-item">
                                        <div className="wpo-event-text">
                                            <div className="title">
                                                <h2>{event.title}</h2>
                                            </div>
                                            <ul>
                                                <li>{event.li1}</li>
                                                <li>{event.li2}</li>
                                                <li>{event.li3}</li>
                                                <li><LocationMap address={event.address}/></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventSectionBJ;