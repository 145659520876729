import React from 'react';
import './Loader.css'; // Asegúrate de que la ruta sea correcta
import heartImage from './../../../src/images/bjwedding/logo/LOGO-BRANDON-BLANCO.avif'; // Ajusta la ruta de la imagen

const Loader = () => {
    return (
        <div className="loader">
            <img src={heartImage} alt="Loading" className="heart" />
        </div>
    );
};

export default Loader;
