import React, { Fragment, useState } from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";
import { Link }  from "react-router-dom";
import './style.css';

const menus = [
    {
        id: 88,
        title: 'Inicio',
        link: '#init',
    },
    {
        id: 89,
        title: 'Ceremonia',
        link: '#ceremony',
    },
    {
        id: 90,
        title: 'Vestimenta',
        link: '#dresscode',
    },
    {
        id: 95,
        title: 'Confirmar Asistencia',
        link: 'https://wa.me/528135116055?text=' + encodeURIComponent("Hola, me gustaría confirmar mi asistencia al evento."),
        isExternal: true,
    }
];



const MobileMenu = () => {
    const [openId, setOpenId] = useState(0);
    const [menuActive, setMenuState] = useState(false);

    const ClickHandler = (link) => {
        if (link.startsWith('#')) {
            const target = document.querySelector(link);
            if (target) {
                // Obtener la altura del navbar cada vez que se hace clic
                const navbarHeight = document.querySelector('.wpo-header-style-4').offsetHeight;
    
                // Calcular la posición de scroll
                const targetPosition = target.getBoundingClientRect().top + window.pageYOffset -30 - navbarHeight;
    
                // Ejecutar el scroll
                window.scrollTo({ top: targetPosition, behavior: 'smooth' });
            }
        }
        setMenuState(false); // Cierra el menú después de hacer clic
    };
    
    
    return (
        <div>
            <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
                <div className="menu-close">
                    <div className="clox" onClick={() => setMenuState(!menuActive)}><i className="ti-close"></i></div>
                </div>

                <ul className="responsivemenu">
                    {menus.map((item, mn) => {
                        return (
                            <ListItem className={item.id === openId ? 'active' : null} key={mn}>
                                {item.submenu ?
                                    <Fragment>
                                        <p onClick={() => setOpenId(item.id === openId ? 0 : item.id)}>
                                            {item.title}
                                            <i className={item.id === openId ? 'fa fa-angle-up' : 'fa fa-angle-down'}></i>
                                        </p>
                                        <Collapse in={item.id === openId} timeout="auto" unmountOnExit>
                                            <List className="subMenu">
                                                <Fragment>
                                                    {item.submenu.map((submenu, i) => (
                                                        <ListItem key={i}>
                                                            <Link onClick={() => ClickHandler(submenu.link)} to={submenu.link}>{submenu.title}</Link>
                                                        </ListItem>
                                                    ))}
                                                </Fragment>
                                            </List>
                                        </Collapse>
                                    </Fragment>
                                    : item.isExternal ? (
                                        <a href={item.link} target="_blank" rel="noopener noreferrer" onClick={() => setMenuState(false)}>
                                            {item.title}
                                        </a>
                                    ) : (
                                        <Link to={item.link} onClick={() => ClickHandler(item.link)}>
                                            {item.title}
                                        </Link>
                                    )
                                }
                            </ListItem>
                        )
                    })}
                </ul>
            </div>

            <div className="showmenu" onClick={() => setMenuState(!menuActive)}>
                <button type="button" className="navbar-toggler open-btn">
                    <span className="icon-bar first-angle"></span>
                    <span className="icon-bar middle-angle"></span>
                    <span className="icon-bar last-angle"></span>
                </button>
            </div>
        </div>
    );
};

export default MobileMenu;
